<template>
  <div class="scroll-notice-container"  @mouseenter="stopScroll" @mouseleave="startScroll" :style="{ backgroundColor: appStore.theme === 'dark' ? '#19191b' : '#f5f8ff',marginBottom: isMobile ? '0px':'20px' }">
    <!-- <img src="../../../assets/inform.png" style="width: 20px;height: 20px;margin-left: 10px;"alt=""> -->
    <!-- <svg t="1722914476330" class="icon SoundOutlined" viewBox="0 0 1194 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4048" width="18" height="18"><path d="M1071.104 205.397333A430.250667 430.250667 0 0 1 1194.666667 509.952c0 120.576-46.421333 228.693333-123.562667 305.92a41.386667 41.386667 0 0 1-60.074667 0c-17.066667-16.554667-17.066667-42.666667 0-59.989333 60.074667-64 98.304-146.773333 98.304-245.248 0-98.474667-38.229333-184.661333-98.304-245.248-17.066667-16.554667-17.066667-42.666667 0-59.989334a41.386667 41.386667 0 0 1 60.074667 0zM657.408 15.957333C716.8-18.432 768 7.68 768.682667 80.810667v865.28c0 69.546667-51.882667 99.157333-111.274667 60.586666L341.333333 813.056V210.261333L657.408 15.957333zM0 339.114667c0-73.045333 55.296-128.853333 128.341333-128.853334H256v602.794667H128.341333C55.296 813.056 0 756.565333 0 683.605333V339.114667z" fill="#0057ff" p-id="4049"></path></svg> -->
    <svg t="1722927053387" class="icon SoundOutlined" :class="appStore.theme === 'dark' ? 'dark-modeSvg' : 'light-mode'" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5379" width="18" height="18"><path d="M541.2 132.3c-7.2-3.3-15.2-5-23.3-5-13.9 0-26.8 5.1-36.5 14.4L280.6 312.4h-158c-11.5 0-23.9 3.9-33.6 10.4h-4l-8.7 9.2c-7.5 7.9-12.2 20.8-12.2 33.8v290.7c0 15 6.7 31.4 16.9 41.6 10.2 10.3 26.6 16.9 41.6 16.9h158.2l200.7 165.6c8.4 7.9 23.3 16 35.6 16 5.6 0 16.5 0 27.1-8 10-4.2 17.8-10.4 23.1-18.6 5.7-8.6 8.4-19.1 8.4-32V184c0-12.9-2.8-23.4-8.4-32-5.8-9-14.6-15.6-26.1-19.7z m-24.8 57.4v642.9L310.2 662.5l-8.2-6.8v0.1H123.3V371.7h179l214.1-182zM899.3 300c-20.9-32.7-46.5-61.1-75.9-84.2l-0.1-0.1c-1.3-1.1-2.2-1.8-2.9-2.4-4.5-2.8-9.6-4.3-15.1-4.3-10.3 0-21.6 5.3-31.1 14.5l-0.1 0.1c-8.4 13.5-3.9 35.9 9 45.2l0.1 0.1c6.1 2.5 14.7 9.7 20.8 15.4 9.4 8.7 23.6 23.7 38.1 45.6 24.3 36.8 53.3 99.7 53.3 190.8 0 91-27.4 153.9-50.3 190.6-13.7 22-27.2 36.9-36.1 45.6-8.6 8.3-15.3 13.5-20.1 15.5l-0.1 0.1c-13.9 9.8-17.2 28.6-8.1 46.7 3.8 7.7 17.2 12.9 27.3 12.9 3.8 0 13.9 0 17.4-3.5 0.7-0.7 1.5-1.4 3.3-2.8 28.1-22.9 52.6-51 72.7-83.8 38.8-63.2 58.5-137.6 58.5-221.4 0-83.4-20.4-157.6-60.6-220.6z" fill="" p-id="5380"></path><path d="M752.7 376.7c-23.8-27.4-48.4-40.2-53.7-42.1h-1.6l-1.9-1.3c-3.2-2.2-7.4-3.3-12-3.3-11.9 0-24.9 7.6-27.8 16.4l-0.3 1-0.6 0.9c-3.8 5.8-4.3 14.2-1.4 22.7 2.9 8.4 8.6 15.1 14.6 17.1l0.6 0.2 0.6 0.3c0.7 0.4 17.2 9.5 33.6 29.7 15.1 18.5 33.1 50.3 33.1 96.7 0 96.6-54.4 128.5-60.7 131.9-13.8 9.4-23 27.9-14.6 40.4l0.3 0.4 0.2 0.5c4.3 8.7 18.9 18.6 27.3 18.6 5.5 0 8.4-0.1 11.7-3.8l2.2-2.2H704.7c6.2-2.3 28.7-15.6 50.6-44.2 20.6-26.9 45.1-74.4 45.1-147.4 0-64.2-25.9-107.5-47.7-132.5z" fill="" p-id="5381"></path></svg>
    <div
      class="scroll-content"
      :style="{ transform: `translateY(-${currentOffset}px)`, transition: `${isJumping ? 'none' : `transform ${transitionDuration}s`}` }"
    >
      <div class="notice-item" :style="{ color: appStore.theme === 'dark' ? '#ccc' : '#000',lineHeight:isMobile ? '35px' : '40px'  }" v-for="(item, index) in notices" :key="index">{{ item }}</div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { useBasicLayout } from '@/hooks/useBasicLayout'
import {useAppStore, useGlobalStoreWithOut } from '@/store'
const appStore = useAppStore()
const { isMobile } = useBasicLayout()
const notices = ref([
  '悠哉 购买了 综合套餐-标准套餐',
  'C 购买了 综合套餐-进阶套餐',
  '蜡像 购买了 综合套餐-进阶套餐',
  '97. 购买了 综合套餐-专业套餐',
  '二硕子 购买了 综合套餐-专业套餐',
  'victor 购买了 综合套餐-进阶套餐',
  '随意而安 购买了 综合套餐-专业套餐',
  '辛寨 购买了 综合套餐-进阶套餐',
  'Dunn 购买了 综合套餐-进阶套餐',
  'Shelden 购买了 综合套餐-进阶套餐',
  '七月份的雨 购买了 综合套餐-进阶套餐',
  '欢喜 购买了 综合套餐-进阶套餐',
  '二宝 购买了 综合套餐-进阶套餐',
  '安寻zxf 购买了 综合套餐-进阶套餐',
  '纸飞机 购买了 综合套餐-进阶套餐',
  '新征程 购买了 综合套餐-专业套餐',
  '大冰de小屋 购买了 综合套餐-专业套餐',
  '御风者 购买了 综合套餐-进阶套餐',
  '词不达意 购买了 综合套餐-专业套餐',
  '早起的我 购买了 综合套餐-进阶套餐',
  '卡文迪许 购买了 综合套餐-进阶套餐',
  '我心永恒 购买了 综合套餐-进阶套餐',
  'Agonys 购买了 综合套餐-进阶套餐',
  '小宋 购买了 综合套餐-进阶套餐',
  '不小心 购买了 综合套餐-进阶套餐',
  'Kenn. 购买了 综合套餐-进阶套餐',
  '悠哉~ 购买了 综合套餐-标准套餐',
  'C 购买了 综合套餐-进阶套餐',
  '蜡像 购买了 综合套餐-进阶套餐',
  '97. 购买了 综合套餐-专业套餐',
  '二硕子 购买了 综合套餐-专业套餐',
  'victor 购买了 综合套餐-进阶套餐',
  '随意而安 购买了 综合套餐-专业套餐',
  '辛寨 购买了 综合套餐-进阶套餐',
  'Dunn 购买了 综合套餐-进阶套餐',
  'Shelden 购买了 综合套餐-进阶套餐',
  '七月份的雨 购买了 综合套餐-进阶套餐',
  '欢喜 购买了 综合套餐-进阶套餐',
  '二宝 购买了 综合套餐-进阶套餐',
  '安寻zxf 购买了 综合套餐-进阶套餐',
  '纸飞机 购买了 综合套餐-进阶套餐',
  '新征程 购买了 综合套餐-专业套餐',
  '大冰de小屋 购买了 综合套餐-专业套餐',
  '御风者 购买了 综合套餐-进阶套餐',
  '词不达意 购买了 综合套餐-专业套餐',
  '早起的我 购买了 综合套餐-进阶套餐',
  '卡文迪许 购买了 综合套餐-进阶套餐',
  '我心永恒 购买了 综合套餐-进阶套餐',
  'Agonys 购买了 综合套餐-进阶套餐',
  '小宋 购买了 综合套餐-进阶套餐',
  '不小心 购买了 综合套餐-进阶套餐',
  'Kenn. 购买了 综合套餐-进阶套餐'
]);


const currentIndex = ref(0);
const transitionDuration = 0.5; // 动画持续时间，单位秒
let interval;
const itemHeight = 40; // 项高度
let scrollHeight = itemHeight * (notices.value.length); // 总滚动高度
const currentOffset = ref(0); // 当前偏移量
const isJumping = ref(false); // 是否正在进行跳跃操作

const scrollNotices = () => {
  if (currentOffset.value + itemHeight >= scrollHeight) {
    // 调整为无过渡效果，实现瞬间跳转回顶部
    isJumping.value = true;
    currentOffset.value = 0; // 重置偏移量

    // 异步方式恢复过渡效果，确保瞬间跳转逻辑执行完成
    setTimeout(() => {
      isJumping.value = false;
      // 这里不直接增加 currentOffset，而是让下一轮interval处理，以避免动画中断
    }, 0);
  } else {
    currentOffset.value += itemHeight;
  }
};

onMounted(() => {
  interval = setInterval(() => {
    scrollNotices();
  }, 3000); // 滚动间隔设置为3秒
});

onUnmounted(() => {
  clearInterval(interval);
});

const stopScroll = () => {
  clearInterval(interval);
};

const startScroll = () => {
  interval = setInterval(() => {
    scrollNotices();
  }, 3000);
};
</script>

<style scoped lang="less">
.SoundOutlined{
  fill: #0057ff;
  margin-left: 17px;
}

.dark-modeSvg{
  fill: #cfccce;
}

.scroll-notice-container {
  overflow: hidden;
  height: 40px;
  position: relative;
  // background-color: #ebeef6;
  display: flex;
  align-items: center;
  border-radius: 4px;
}
.scroll-content {
  /* position: absolute; */
  width: 100%;
  height: 100%;
  margin-left: 10px
}
.notice-item {
  height: 40px;
  // line-height: 40px;
  /* display: flex;
  align-items: center; */
}
</style>