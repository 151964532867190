<script setup lang='ts'>
import type { TabsInst } from 'naive-ui'
import { NButton, NIcon, NModal, NResult, NTabPane, NTabs } from 'naive-ui'
import { computed, nextTick, ref } from 'vue'
import { CloseOutline } from '@vicons/ionicons5'
import Phone from './Login/Phone.vue'
import Email from './Login/Email.vue'
import Wechat from './Login/Wechat.vue'
import { useAuthStore , useGlobalStore } from '@/store'
import { useBasicLayout } from '@/hooks/useBasicLayout'
import bannerImg from '@/assets/login-banner.jpg'
import Retrieve from '@/views/userCenter/components/retrieve.vue'
import RetrieveAsEmail from '@/views/userCenter/components/RetrieveAsEmail.vue'

defineProps<Props>()

let timer: any
const authStore = useAuthStore()
const activeCount = ref(false)
const wxLoginUrl = ref('')
const sceneStr = ref('')
const tabsRef = ref<TabsInst | null>(null)
const showWxLogin = ref(true)
const tabName = ref('email')
const { isMobile } = useBasicLayout()
const emailLoginStatus = computed(() => Number(authStore.globalConfig.emailLoginStatus) === 1)
const wechatRegisterStatus = computed(() => Number(authStore.globalConfig.wechatRegisterStatus) === 1)
const phoneLoginStatus = computed(() => Number(authStore.globalConfig.phoneLoginStatus) === 1)
const loginDialogImg = computed(() => authStore.globalConfig.loginDialogImgUrl || bannerImg)
const globalStore = useGlobalStore()
const forgetIsShow = computed(() => globalStore.forgetShow)
const tabEmailOrPhone = computed(() => globalStore.changeResetState)

/* 没有打开任何登录 */
const disabledReg = computed(() => {
  return !wechatRegisterStatus.value && !phoneLoginStatus.value && !emailLoginStatus.value
})


interface Props {
  visible: boolean
}

function openDialog() {
  /* 没打开微信的话使用邮箱或者手机号 */
  if (!wechatRegisterStatus.value) {
    showWxLogin.value = false
    if (phoneLoginStatus.value)
      changeLoginType('phone')

    if (emailLoginStatus.value)
      changeLoginType('email')
  }
}

function handleCloseDialog() {
  clearInterval(timer)
  wxLoginUrl.value = ''
  sceneStr.value = ''
  activeCount.value = false
}

/* 切换登录类型 */
function changeLoginType(type: string) {
  if (type === 'wechat') {
    showWxLogin.value = true
  }
  else {
    showWxLogin.value = false
    tabName.value = type
    nextTick(() => {
      tabsRef.value?.syncBarPosition()
    })
  }
}
</script>

<template>
  <NModal :show="visible" :on-after-enter="openDialog" :on-after-leave="handleCloseDialog">
    <div class="w-[600px] h-[600px] bg-transparent rounded-md overflow-hidden dark:bg-slate-800" :class="isMobile ? 'px-[5px]' : ''">
      <div class="absolute top-3 right-3 cursor-pointer z-30" @click="authStore.setLoginDialog(false)">
        <NIcon size="20" color="#0e7a0d">
          <CloseOutline />
        </NIcon>
      </div>
      <div class="bg-transparent m-0 flex ">
        <!-- <div v-if="!isMobile" class="w-[500px] h-[600px] bg-[#E3EEFD] overflow-hidden">
          <img :src="loginDialogImg" class="object-cover w-full">
        </div> -->

        <div v-if="disabledReg" class="flex-1 bg-white flex justify-center items-center dark:bg-[#34373c] h-[600px]">
          <NResult
            size="small"
            status="403"
            title="网站已经关闭注册通道"
            description="请联系管理员开通吧"
          >
            <template #footer>
              <NButton size="small" @click="authStore.setLoginDialog(false)">
                知道了
              </NButton>
            </template>
          </NResult>
        </div>
        <div v-if="!disabledReg" class="flex-1 bg-white dark:bg-[#34373c] h-[600px]" :class="isMobile ? 'rounded-md' : ''">
          <Wechat v-if="wechatRegisterStatus && showWxLogin" @change-login-type="changeLoginType" />
          <div class="mt-[50px]" >
            <NTabs v-if="!showWxLogin" v-show="!forgetIsShow" ref="tabsRef" v-model:value="tabName" animated justify-content="space-evenly">
              <NTabPane v-if="emailLoginStatus && !forgetIsShow" name="email" tab="邮箱号登录">
                <Email @change-login-type="changeLoginType" />
              </NTabPane>
              <NTabPane v-if="phoneLoginStatus && !forgetIsShow" name="phone" tab="手机号登录">
                <Phone @change-login-type="changeLoginType" />
              </NTabPane>
              <!-- <NTabPane v-if="forgetIsShow" name="forget" tab="忘记密码">
                
              </NTabPane> -->
            </NTabs>
            <Retrieve v-if="tabEmailOrPhone == 'phone'"  />
            <RetrieveAsEmail v-if="tabEmailOrPhone == 'email'" />
          </div>
        </div>
      </div>
      <!-- register -->
    </div>
  </NModal>
</template>
