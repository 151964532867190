<script setup lang='ts'>
import { NButton, NGrid, NGridItem, NIcon, NModal, NScrollbar, NSkeleton, NSpace, useDialog, useMessage, NTabPane, NTabs,NTag,NCountdown,CountdownProps   } from 'naive-ui'
import { CloseOutline } from '@vicons/ionicons5'
import { computed, ref } from 'vue'
import { useBasicLayout } from '@/hooks/useBasicLayout'
import { fetchGetPackageAPI,fetchGetAllPackageNameAPI } from '@/api/crami'
import { fetchOrderBuyAPI } from '@/api/order'
import { fetchGetJsapiTicketAPI } from '@/api/user'
import { CheckmarkCircle } from '@vicons/ionicons5'
import advertising from '@/views/pay/components/advertising.vue'
import { TitleBar } from '@/components/base'
import type { ResData } from '@/api/types'
import preferentialIcon from '@/assets/images/preferential.png'
import { useAuthStore, useGlobalStoreWithOut,useAppStore } from '@/store'
defineProps<Props>()
declare let WeixinJSBridge: any
declare let wx: any
const authStore = useAuthStore()
const useGlobalStore = useGlobalStoreWithOut()
const loading = ref(true)
const { isSmallMd,isMobile } = useBasicLayout()
const packageList = ref<Pkg[]>([])
const message = useMessage()
const dialog = useDialog()
const dialogLoading = ref(false)
const pkgType = ref('')
const appStore = useAppStore()

const isWxEnv = computed(() => {
  const ua = window.navigator.userAgent.toLowerCase()
  return ua.match(/MicroMessenger/i) && ua?.match(/MicroMessenger/i)?.[0] === 'micromessenger'
})

const payPlatform = computed(() => {
  const { payHupiStatus, payEpayStatus, payMpayStatus, payWechatStatus } = authStore.globalConfig
  if (Number(payWechatStatus) === 1)
    return 'wechat'

  if (Number(payMpayStatus) === 1)
    return 'mpay'

  if (Number(payHupiStatus) === 1)
    return 'hupi'

  if (Number(payEpayStatus) === 1)
    return 'epay'
  return null
})

const payChannel = computed(() => {
  const { payEpayChannel, payMpayChannel } = authStore.globalConfig
  if (payPlatform.value === 'mpay')
    return payMpayChannel ? JSON.parse(payMpayChannel) : []

  if (payPlatform.value === 'epay')
    return payEpayChannel ? JSON.parse(payEpayChannel) : []

  if (payPlatform.value === 'wechat')
    return ['wxpay']

  if (payPlatform.value === 'hupi')
    return ['wxpay']

  return []
})

interface Props {
  visible: boolean
}

interface Pkg {
  id: number
  name: string
  coverImg: string
  des: string
  price: number
  model3Count: number
  model4Count: number
  drawMjCount: number
  extraReward: number
  extraPaintCount: number
  createdAt: Date
}
function openDialog() {
  openDrawerAfter()
  if (isWxEnv.value)
    jsapiInitConfig()
}

function handleCloseDialog() {
  packageList.value = []
  loading.value = true
 
}

/* 微信环境jsapi注册 */
async function jsapiInitConfig() {
  const url = window.location.href.replace(/#.*$/, '')
  const res: ResData = await fetchGetJsapiTicketAPI({ url })
  const { appId, nonceStr, timestamp, signature } = res.data
  if (!appId)
    return

  wx.config({
    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    appId, // 必填，公众号的唯一标识
    timestamp, // 必填，生成签名的时间戳
    nonceStr, // 必填，生成签名的随机串
    signature, // 必填，签名
    jsApiList: ['chooseWXPay'], // 必填，需要使用的JS接口列表
  })

  wx.ready(() => {})
  wx.error(() => {
    alert('err')
  })
}

function onBridgeReady(data: { appId: string; timeStamp: string; nonceStr: string; package: string; signType: string; paySign: string }) {
  const { appId, timeStamp, nonceStr, package: pkg, signType, paySign } = data
  WeixinJSBridge.invoke('getBrandWCPayRequest', {
    appId, // 公众号ID，由商户传入
    timeStamp, // 时间戳，自1970年以来的秒数
    nonceStr, // 随机串
    package: pkg,
    signType, // 微信签名方式：
    paySign, // 微信签名
  },
  (res: any) => {
    if (res.err_msg === 'get_brand_wcpay_request:ok') {
      // message.success('购买成功、祝您使用愉快!')
      authStore.getUserInfo()
      useGlobalStore.updateGoodsDialog(false)
      useGlobalStore.updatePaySuccesslog(true)
    }
    else {
      message.warning('您还没有支付成功哟！')
    }
  })
}

async function handleBuyGoods(pkg: Pkg) {
  if (dialogLoading.value)
    return
  
  let price = promotionPriceFunc(pkg)
  pkg.price = price

  useGlobalStore.updateOrderInfo({ pkgInfo: pkg })
  // 如果是微信环境判断有没有开启微信支付,开启了则直接调用jsapi支付即可
  if (isWxEnv.value && payPlatform.value === 'wechat' && Number(authStore.globalConfig.payWechatStatus) === 1) {
    if (typeof WeixinJSBridge == 'undefined') {
      if (document.addEventListener) {
        document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false)
      }
      else if (document.attachEvent) {
        document.attachEvent('WeixinJSBridgeReady', onBridgeReady)
        document.attachEvent('onWeixinJSBridgeReady', onBridgeReady)
      }
    }
    else {
      const res: ResData = await fetchOrderBuyAPI({ goodsId: pkg.id, payType: 'jsapi' })
      const { success, data } = res
      success && onBridgeReady(data)
    }
    return
  }

  /* 其他场景打开支付窗口 */
  
  useGlobalStore.updateGoodsDialog(false)
  useGlobalStore.updatePayDialog(true)
  // dialogLoading.value = true
  // const { id: goodsId, name, des } = pkg
  // try {
  //   /* 如果在微信环境 则微信官方支付渠道为jsapi支付 */
  //   if (payPlatform.value === 'wechat')
  //     payType = isWxEnv.value ? 'jsapi' : 'native'

  //   const res: ResData = await fetchOrderBuyAPI({ goodsId, payType })
  //   dialogLoading.value = false
  //   const { success, data } = res
  //   if (success) {
  //     /* 如果是微信环境并且开启了微信登录则调用jsapi支付 */
  //     if (isWxEnv.value && payPlatform.value === 'wechat') {
  //       if (typeof WeixinJSBridge == 'undefined') {
  //         if (document.addEventListener) {
  //           document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false)
  //         }
  //         else if (document.attachEvent) {
  //           document.attachEvent('WeixinJSBridgeReady', onBridgeReady)
  //           document.attachEvent('onWeixinJSBridgeReady', onBridgeReady)
  //         }
  //       }
  //       else {
  //         onBridgeReady(data)
  //       }
  //       return
  //     }

  //     useGlobalStore.updateOrderInfo({ ...data, pkgInfo: pkg })
  //     useGlobalStore.updateGoodsDialog(false)
  //     const { isRedirect, redirectUrl } = data
  //     if (isRedirect)
  //       window.open(redirectUrl)

  //     else
  //       useGlobalStore.updatePayDialog(true)
  //   }
  // }
  // catch (error) {
  //   dialogLoading.value = false
  // }
}

const packageNameOptions = ref([])
const currentTab = ref();

async function openDrawerAfter() {
  loading.value = true
  try {
    const res2 = await fetchGetAllPackageNameAPI({ page: 1, size: 30 })
    packageNameOptions.value =  res2.data.rows;
    let type = await res2.data.rows[0].name
    const res: ResData = await fetchGetPackageAPI({ status: 1, size: 30, typeName: type})
    currentTab.value = packageNameOptions.value[0].id
    packageList.value = res.data.rows;
    loading.value = false
  }
  catch (error) {
    loading.value = false
  }
}



function handleSuccess(pkg: Pkg) {
  const { name } = pkg
  dialog.success({
    title: '订单确认',
    content: `欢迎选购、确定购买${name}么！`,
    negativeText: '我再想想',
    positiveText: '确认购买',
    onPositiveClick: () => {
      if (!payChannel.value.length)
        message.warning('管理员还未开启支付！')

      handleBuyGoods(pkg)
    },
  })
}

const activeTab = ref(1);
const packageId = ref(null)
packageId.value = authStore.userBalance.packageId;
function handleTabChange(value){
  const selectedTab = packageList.value.find(tab => tab.id === value);
}

function updateTabs(val: number) {
  const selectedTab =  packageNameOptions.value.find(tab => tab.id === val);
  pkgType.value = selectedTab.name
  queryPkg()
  getClass(val)
}

async function queryPkg() {
  try {
    loading.value = true
    const res: ResData = await fetchGetPackageAPI({ status: 1, size: 30, typeName: pkgType.value })
    packageList.value = res.data.rows
   
    activeTab.value = packageList.value[0].id
    loading.value = false
  }
  catch (error) {
    loading.value = false
  }
}


function openVipBtn(){
  authStore.getUserInfo()
  if(activeTab.value == packageId.value) {
    message.warning('您已开通此会员,不可重复开通哦')
    return
  }

  const selectedTabDefault = packageList.value.find(tab => tab.id === activeTab.value);
  if (packageId.value == 0 && Number(selectedTabDefault.days) <= 0) {
    message.warning('专属套餐需要开通会员后才能购买哦')
    return
  }
  handleSuccess(selectedTabDefault)
}

const renderCountdown: CountdownProps['render'] = ({
      minutes,
      seconds
    }) => {
      return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }


function equitiesItem(value){
  return value.split('，');
}


function formatPrice(price) {
    let numPrice = parseFloat(price);
    // 判断价格是否为整数
    if (Number.isInteger(numPrice)) {
        return numPrice.toString();
    } else {
        return numPrice.toFixed(2);
    }
}



function promotionPriceFunc(itemStatus: object) {
  const userBuyRecord = computed(() => { return authStore.userBuyRecord })
  //如果开启了促销活动并且用户是新用户，那么取促销价格
  if(userBuyRecord.value == false && itemStatus.promotionUserStatus == 1){
    return formatPrice(itemStatus.promotionPrice)
  }else if(userBuyRecord.value != false && itemStatus.promotionStatus == 1){
    return formatPrice(itemStatus.price) 
  }else{
    return formatPrice(itemStatus.price)
  }
}


function promotionTagShow(itemStatus: object) {
  const userBuyRecord = computed(() => { return authStore.userBuyRecord })
  if(userBuyRecord.value == false && itemStatus.promotionUserStatus == 1){ // 新用户且开启了新用户的促销
    return true
  }else if(userBuyRecord.value == false && itemStatus.promotionStatus == 1){ // 新用户且开启了促销
    return true
  }else if(userBuyRecord.value != false && itemStatus.promotionStatus == 1){
    return true
  }else{
    return false
  }
}

function promotionTagInfo(itemStatus: object) {
  const userBuyRecord = computed(() => { return authStore.userBuyRecord })
  if(userBuyRecord.value == false && itemStatus.promotionUserStatus == 1){
    return itemStatus.promotionUserInfo
  }else if(userBuyRecord.value == false && itemStatus.promotionStatus == 1){
    return itemStatus.promotion
  }else if(userBuyRecord.value != false && itemStatus.promotionStatus == 1){
    return itemStatus.promotion
  }else{
    return itemStatus.promotion
  }
}

const tips = computed(() => {
  return isMobile.value ? '欢迎加入我们的会员特权！选择我们的包月套餐，您将尽享会员专属福利，开启一段超值的AI体验之旅！' : '欢迎加入我们的会员特权！选择我们的包月套餐，您将尽享会员专属福利，开启一段超值的AI体验之旅！'
})

const classList = ref([])
function getClass(val: number){
  const className = val == 1 ? 'blockLeftStyle' : 'blockRightStyle';
  classList.value = []
  classList.value.push(className);
}

function caleDateName(val: number){
  switch (val) {
    case 31:
      return '月'
      break;
    case 90:
      return '季'
      break;
    case 365:
      return '年'
      break;
    default:
      break;
  }
}



</script>

<template>
  <NModal :show="visible" :style="{ maxWidth: `${packageList.length > 4 ? 1200 : packageList.length * 250}px`, minWidth: isSmallMd ? '100%' : '1000px', height:'100vh',overflow:'hidden' }" :on-after-enter="openDialog" :on-after-leave="handleCloseDialog">
    <div class="bg-white rounded dark:bg-[#131314] max-h-4/5">
      <div class="payHeader">
        <advertising :items="announcements" :interval="2000" />
        <TitleBar title="会员中心" :des="tips" :class="[isMobile ? 'px-3' : 'px-24']" />
      </div>
      <div class="flex justify-center items-center mt-4" :style="{ height: isMobile ? '60px' : '120px',borderRadius:'20px' }">
        <NTabs v-model:value="currentTab" type="segment" :class="classList" :style="{ width: isMobile ? '90%' : '400px' }" @update:value="updateTabs">
          <NTabPane :name="item.id" :tab="item.name" v-for="item in packageNameOptions"/>
        </NTabs>
      </div>
      <n-tabs type="card" class="n-tabsy" @update:value="handleTabChange" v-model:value="activeTab" :class="[{'dark-mode': appStore.theme === 'dark', 'light-mode': appStore.theme !== 'dark' }]">
        <n-tab-pane :name="item.id" tab="" v-for="item in packageList" :key="item.id">
          <template #tab>
            <NScrollbar class="px-2">
              <div class="tab-title">
                <div class="indate">{{ item.name  }}</div>
                <div class="price">
                  <div class="priceWrap">
                    <span>￥</span>
                    <span>{{promotionPriceFunc(item)}}</span>
                  </div>
                  <span class="sprit" v-if="item.days > 1" >/{{ caleDateName(item.days) }} </span>
                </div>
                <div class="originalPrice">
                  已优惠 ￥{{ item.originalPrice - item.price }}
                </div>
                <div v-show="promotionTagShow(item)" class="activity bg-[#0057ff] inline-block text-white px-[5px] py-[2px] text-[10px] rounded-tl rounded-br">
                  <span>{{ promotionTagInfo(item) }}</span>
                </div>
              </div>
            </NScrollbar>
          </template>
          <div class="tab-content mx-[20rpx] p-[30rpx] rounded-lg flex items-center mb-[20rpx]">
            <div class="tab-content-text">
              <div style="white-space:nowrap;" class="font-semibold">{{ item.name }}：</div>
              <div class="equity">
                <div class="equityItem" v-for="items in equitiesItem(item.des)">
                  <span class="dot bg-[#000] dark:bg-[#fff]"  ></span>
                  <span class="text text-[13px]">{{ items }}</span>
                </div>
              </div>
            </div>
          </div>
        </n-tab-pane>
      </n-tabs>
      <div class="backHome fixed bottom-[110px] bg-[#f7f7fa] dark:bg-[#2b2b2c] rounded-[50%] right-[20px] flex flex-col items-center justify-center z-999" style="box-shadow: rgba(255, 255, 255 0.2) 0px 3px 6px; z-index: 999;" @click="useGlobalStore.updateGoodsDialog(false)">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12H3l9-9l9 9h-2"></path><path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-7"></path><path d="M9 21v-6a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v6"></path></g></svg>
        首页
      </div>
      <div class="openVip">
        <span class="openVipBtn mx-[30rpx] mt-[20rpx] " @click="openVipBtn" :style="{cursor: activeTab == packageId ? 'not-allowed':'pointer' }">
          立即开通
        </span>
      </div>
      <!-- <div v-if="!loading" class="p-4">
        <NGrid :x-gap="15" :y-gap="15" :cols="isSmallMd ? 1 : packageList.length > 4 ? 4 : packageList.length" class="mt-3">
          <NGridItem v-for="(item, index) in packageList" :key="index">
            <NCard size="small" embedded>
              <template #header>
                <div class="relative">
                  <b>{{ item.name }}</b>
                  <img v-if="item.extraReward === 1" :src="preferentialIcon" class="w-8 absolute -right-4 -top-3">
                </div>
              </template>
              <template #cover>
                <img :src="item.coverImg" class="h-[130px] object-cover">
              </template>
              <div>
                <p>{{ item.des }}</p>
                <div class="flex justify-between items-end min-h-28">
                  <span class="text-sm font-bold mr-1 w-[120px]">基础模型额度</span>
                  <span class="font-bold">{{ item.model3Count }}</span>
                </div>
                <div class="flex justify-between items-end min-h-28">
                  <span class="text-sm font-bold mr-1 w-[120px]">高级模型额度</span>
                  <span class="font-bold">{{ item.model4Count }}</span>
                </div>
                <div class="flex justify-between items-end min-h-28">
                  <span class="text-sm font-bold mr-1 w-[120px]">MJ绘画额度</span>
                  <span class="font-bold">{{ item.drawMjCount }}</span>
                </div>
                <div class="flex justify-between items-end mt-5">
                  <i class="text-xl text-[red] font-bold">{{ `￥${item.price}` }}</i>
                  <NButton type="primary" dashed size="small" @click="handleSuccess(item)">
                    购买套餐
                  </NButton>
                </div>
              </div>
            </NCard>
          </NGridItem>
        </NGrid>
      </div> -->
      <!-- 占位符 -->
      <!-- <div v-if="loading" class="p-4">
        <NGrid :x-gap="15" :y-gap="15" :cols="isSmallMd ? 1 : 4" class="mt-3">
          <NGridItem v-for="(index) in 4" :key="index">
            <NSpace vertical>
              <NSkeleton height="130px" width="100%" />
              <NSkeleton height="210px" width="100%" :sharp="false" />
            </NSpace>
          </NGridItem>
        </NGrid>
      </div> -->
    </div>
  </NModal>
</template>
<style scoped lang="less">
::v-deep .n-tabsy{
   .n-tabs-tab{
    margin-right: 4px;
    border-radius: 4px !important;
    // border: 1px solid #e5e5ea !important;
    // background-color: #fff !important;
    padding: 0 0 !important;
    width: 120px;
    height: 116px;
  }

  .dot{
    height: 5px;
    width: 5px;
    border-radius: 50%;
    margin:0 8px;
  }

  .dark-mode .n-tabs-tab--active {
    background-color: #f5f8ff !important;
    
    // .indate{
    //   color: #d5d4d4 !important;
    // }
    // .price{
    //   color: #d5d4d4 !important;
    // }
    // .originalPrice{
    //   color: #d5d4d4 !important;
    // }
  }

  .n-tabs-tab--active{
    background-color: #ebeef6 !important;
    border: 1px solid #dfe9ff !important;
  }

  .tab-title{
    margin: 22px 0 0px 0;
    padding: 0 6px;
    .indate{
      color: rgb(153 153 153 / 1);
      font-size: 12px;
      text-align: center;
    }
    .price{
      // color: #000;
      color: #9e9d9d;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      .priceWrap{
        align-items: baseline;
        display: flex;
        span{
          &:nth-child(1){
          font-size:14px;
          padding: 0;
          margin: 0;
        }
        &:nth-child(2){
          font-size: 1.875rem;
          font-weight: bold;
        }
        }
      }

        .sprit{
          margin: 0 2px;
        }
        .vip{
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          max-width: 60px;
        }
    }
    .originalPrice{
      color: #9e9d9d;
      font-size: 11px;
      text-align: center;

    }
    .activity{
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .tab-content {
    margin-top: 10px;
    .tab-content-text {
      display: flex;
      flex-direction: column;
      .equity {
        display: flex;
        flex-direction: column;
        .equityItem {
          display: flex;
          align-items: center;
          margin-right: 4px;
          margin-top: 6px;
          svg {
            height: 1em;
            width: 1em;
            display: block;
            vertical-align: middle;
            margin: 0 8px;

            path {
              color: #003bff;
            }
          }
        }

      }
    }
  }
 
}
.line{
  border-bottom: 1px solid #e5e5ea;
  margin: 10px 0;
}

.dark-mode-vipStatus path{
  color: #cfccce !important;
}

.openVip{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.openVipBtn{
  // background: linear-gradient(90deg, #846ef7 0, #79bbff 100%);
  background: linear-gradient(45deg, #0088fd 0%, #003bff 50%, #001faa 100%);
  border-radius: 50px;
  color: #fff;
  // height: 40px;
  line-height: 30px;
  padding: 10px 30px;
  cursor: pointer;
  height: 2.5625rem;
  font-size: 1rem;
  color: rgb(255, 255, 255);
  width: 90%;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
}

.payHeader{
  background-position: center center;
  // background: linear-gradient(134.97deg, rgb(90, 90, 90) -.09%, rgb(73, 73, 73) 60.5%);
  position: relative;
  .textColor{
    color: #846ef7;
  }
  .textColor2{
    color: rgb(154, 150, 148);
    font-size: .8125rem;
  }
}

.n-tabs .n-tabs-nav.n-tabs-nav--top.n-tabs-nav--card-type .n-tabs-pad{
    border-bottom:1px solid transparent !important;
  }

.scroll-notice-container{
  background-color:rgba(255, 239, 230, 0.1);
  color: #fff;
  font-size: 13px;
  height: 34px;
}
::v-deep .n-tabs-tab{
  // padding: 4px 6px !important;
  // width: 100%;
  border: 1px solid transparent;
  border-radius: 6px !important;
 
}

::v-deep .n-tabs-tab-wrapper{
  min-width: 100px;
}

// ::v-deep .n-tabs-tab-wrapper .n-tabs-tab--active{
//   border: 1px solid #e5e5ea !important;
//   background-color: #f7f7fa !important;
// }

::v-deep .n-tabs-nav-scroll-wrapper{
  padding-left: 0.625rem;
}

::v-deep .n-tabs-tab__label{
  width: 100%;
  height: 100%;
  justify-content: center;
}

::v-deep .n-tab-pane{
  padding: 10px 10px !important;
}

::v-deep .n-tabs .n-tabs-nav.n-tabs-nav--top.n-tabs-nav--card-type .n-tabs-pad{
    border-bottom:1px solid transparent !important;
  }

// .n-tabs .n-tabs-tab .n-tabs-tab__label
.backHome{
  font-size: 12px;
  width: 45px;
  height: 45px;
  // background-color: #f7f7fa;
}

.backHome svg{
  width: 20px;
}

::v-deep .n-tabs.light-mode .n-tabs-tab--active{
  background-color: #f5f8ff !important;
}

::v-deep .n-tabs.light-mode .n-tabs-tab--active{
  background-color: #f5f8ff !important;
  .price{
    color: #19191b !important;
  }
  .originalPrice{
    color: #19191b !important;
  }
}


::v-deep .n-tabs.dark-mode .n-tabs-tab--active{
  background-color: #19191b !important;
  border: 1px solid transparent !important;
  .price{
    color: #d5d4d4 !important;
  }
  .priceWrap{
    color: #d5d4d4 !important;
  }


}

::v-deep .n-tabs.dark-mode .n-tabs-nav.n-tabs-nav--card-type .n-tabs-tab{
  background-color: transparent;
}

::v-deep.n-tabs .n-tabs-rail .n-tabs-capsule{
  transition: none;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

::v-deep.n-tabs.blockLeftStyle .n-tabs-rail .n-tabs-capsule{
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;

}

::v-deep .n-tabs.blockRightStyle .n-tabs-rail .n-tabs-capsule{
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}



::v-deep .n-tabs .n-tabs-nav.n-tabs-nav--top.n-tabs-nav--card-type .n-tabs-tab-pad{
  border-bottom: 1px solid transparent !important;
}

::v-deep .n-tabs .n-tabs-rail{
  border-radius: 30px;
  padding: 0px;
}

</style>
