import { defineStore } from 'pinia'
import type { GlobalState } from './helper'
import { store } from '@/store'


export const useReplicateStore = defineStore('replicate-store', {
  state: (): GlobalState => ({
    ModelsList: {}
  }),

  actions: {
    updateModelsList(state: object){
      this.ModelsList = state 
    }
  },
})

export function useReplicateStoreWithOut() {
  return useReplicateStore(store)
}