<template>
    <div @click="handleClick" :disabled="countdown > 0" class="sendCode dark:bg-[#9e9d9d] bg-[#0057ff] text-[#fff] dark:text-[#000] " >
        <template v-if="countdown > 0">
            {{ countdown }}秒后重新发送
        </template>
        <template v-else>
            发送验证码
        </template>
    </div>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits  } from 'vue';
import { useMessage } from 'naive-ui'
const ms = useMessage()

// 定义初始倒计时时间
const initialCountdown = 60;
const countdown = ref(0);

const props = defineProps({
  sendPhone: String
})


interface Emits {
    (e: 'updatePhone'): void
}
const emit = defineEmits <Emits> ()

// 点击处理函数
const handleClick = () => {
  if(props.sendPhone == 0){
    ms.warning('请输入正确的账号！')
    return
  }
  // 发送验证码的逻辑
  emit('updatePhone')
  // 启动倒计时
  startCountdown();
};

// 开始倒计时的函数
const startCountdown = () => {
    if (countdown.value > 0) return;

    countdown.value = initialCountdown;
    const interval = setInterval(() => {
        if (countdown.value > 0) {
            countdown.value--;
        } else {
            clearInterval(interval);
        }
    }, 1000);
};
</script>

<style scoped>
.sendCode {
    line-height: 34px;
    padding: 0 12px;
    border: 1px solid #ccc;
    /* background-color: #0057ff; */
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    white-space: nowrap;
    outline: none;
    position: relative;
    z-index: auto;
    border: none;
    display: inline-flex;
    flex-wrap: nowrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    margin-left: 14px;
}

button:disabled {
    background-color: #846EF7FF;
    cursor: not-allowed;
}
</style>