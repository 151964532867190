<script setup lang='ts'>
import { NModal , NSpace } from 'naive-ui'
import { computed,onMounted,ref } from 'vue'
import { useRouter } from 'vue-router'
import { useBasicLayout } from '@/hooks/useBasicLayout'
import { useGlobalStoreWithOut, useAuthStore } from '@/store'

const useGlobalStore = useGlobalStoreWithOut()
const authStore = useAuthStore()
const router = useRouter()
const { isMobile } = useBasicLayout()

interface Props {
  visible: boolean
}
defineProps<Props>()
interface PkgProperty {
  name: string
  des: string
  packCategory: string

}

const orderInfo = computed(() => useGlobalStore?.orderInfo)
const userBalanceInfo = computed(() => authStore?.userBalance);

function skipChat(){
  // useGlobalStore.updatePayDialog(false)
  useGlobalStore.updatePaySuccesslog(false)
  // queryPkg() // 对于个人中心中套餐信息没有更新的问题，这里重新调用一下queryPkg方法
  // router.push({ name: 'Chat' })
}


// const loading = ref(false)
// const packageList = ref([])
// let entyObj = ref<PkgProperty>({})
// async function queryPkg() {
//   try {
//     loading.value = true
//     const userBalanceInfos = computed(() => authStore?.userBalance);
//     const res:ResData = await fetchGetPackageAPI({ status: 1, size: 30 })
//     packageList.value = res.data.rows
//     packageList.value.forEach(pack =>{
//       if(userBalanceInfos.value.packageId == pack.id){
//         entyObj.value.name = pack.name
//         entyObj.value.des = pack.des
//         entyObj.value.packCategory = pack.packCategory
//         authStore.updateRechargeInfo(entyObj.value)
//       }
//     })
//     loading.value = false
//   }
//   catch (error) {
//     loading.value = false
//   }
// }

function equitiesItem(value){
  return value?.split('，');
}

function openDrawerAfter(){
  authStore.updateRechargeInfo(orderInfo.value.pkgInfo)
}

function handleCloseDialog(){
  useGlobalStore.updateOrderInfo({})
}

onMounted(() => {
  
})

</script>

<template>
  <NModal :show="visible" style="width: 90%; max-width: 550px; height: 500px" :on-after-enter="openDrawerAfter" :on-after-leave="handleCloseDialog">
    <div class="flex items-center flex-col  p-10 bg-white rounded dark:bg-slate-800" >
      <img src="@/assets/wxpay.png" alt="" class="w-20">
      <div class="mt-4 font-bold" :class="[isMobile ? 'text-base' : 'text-lg']">你已成功购买“{{ orderInfo.pkgInfo?.packCategory}}-{{ orderInfo.pkgInfo?.name }}”</div>
      <div class="content">
        <div class="flex flex-col mt-4 equity">
          <div class="mt-2 font-semibold">你已获得以下权益：</div>
          <span v-for="item in equitiesItem(orderInfo.pkgInfo?.des)" :class="[isMobile ? 'text-xs' : '']">● {{ item }}</span>
        </div>
        <div class="w-full h-px bg-slate-200 mt-4" ></div>
        <div class="mt-2" :class="[isMobile ? 'text-xs' : '']" v-if="orderInfo.pkgInfo?.days > 0" >套餐到期时间：{{ userBalanceInfo.expirationTime || '' }}</div>
        <div class="openVips mt-4">
        <span class="openVipBtn" @click="skipChat">
          返回主页
        </span>
      </div>
      </div>
    </div>
  </NModal>
</template>

<style lang="less" scoped>
.equity{
  span{
    margin-top: 4px;
  }
}
.openVips{
  display: flex;
  justify-content: center;
  .openVipBtn{
    background: linear-gradient(to right, #1c54f9, #1b56f1);
    font-family: 'Microsoft YaHei-Bold', sans-serif;
    padding: 10px 20px;
    border-radius: 7px;
    box-shadow: 0 4px 10px 0 rgba(0, 3, 15, 0.37);
    color: #fff;
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
    font-weight: bold;
    text-align: center;
  }
}

</style>
