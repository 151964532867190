import { ss } from '@/utils/storage'

const LOCAL_NAME = 'paintingSetting'

export interface PaintingState {
  autoTranslate: boolean
  autoOptimize: boolean
  speed: string
}

export function defaultSetting(): PaintingState {
  return { autoTranslate: true, autoOptimize: false, speed: 'fast' }
}

export function getLocalSetting(): AppState {
  const localSetting: PaintingState | undefined = ss.get(LOCAL_NAME)
  return { ...defaultSetting(), ...localSetting }
}

export function setLocalSetting(setting: AppState): void {
  ss.set(LOCAL_NAME, setting)
}
