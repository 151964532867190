<script setup lang='ts'>
import { computed, defineAsyncComponent, nextTick, onMounted, ref, shallowRef, watch } from 'vue'
import { NAvatar, NIcon, NScrollbar, NTooltip, useMessage } from 'naive-ui'
import { PersonAddOutline } from '@vicons/ionicons5'
import { useRoute, useRouter } from 'vue-router'
import Logo from '../components/Logo.vue'
import { HoverButton, SvgIcon } from '@/components/common'
import defaultAvatar from '@/assets/avatar.png'
import { fetchQueryMenuAPI } from '@/api/config'

import { useAppStore, useAuthStore, useGlobalStoreWithOut } from '@/store'
import { useBasicLayout } from '@/hooks/useBasicLayout'
const Setting = defineAsyncComponent(() => import('@/components/common/Setting/index.vue'))

const appStore = useAppStore()
const authStore = useAuthStore()
const useGlobalStore = useGlobalStoreWithOut()
const message = useMessage()
const track = ref(null)
appStore.setEnv()

const avatar = computed(() => authStore.userInfo.avatar)
const route = useRoute()
const router = useRouter()
const show = ref(false)
const isLogin = computed(() => authStore.isLogin)
const darkMode = computed(() => appStore.theme === 'dark')
const env = computed(() => appStore.env)
const logInIcon = shallowRef(PersonAddOutline)

const menuList = ref<MenuItem[]>([])

async function queryMenu() {
  const res: any = await fetchQueryMenuAPI({ menuPlatform: 1 })
  if (!res.success)
    return
  menuList.value = res.data
  nextTick(() => {
    calcExceededTotalWidth()
  })
}

/* 如果开启了这个模式 并且用户没有分销人资格就不显示这个菜单 */

const list: any = computed(() => {
  if (Number(authStore.globalConfig.useDistributionMode) === 1 && !authStore.userInfo.isDistribution)
    return menuList.value?.filter(t => t.menuPath !== '/share')

  return menuList.value
})

interface MenuItem {
  menuName: string
  menuPath: string
  menuIcon: string
  menuTipText: string
  menuIframeUrl: string
  isJump: boolean
  isNeedAuth: boolean
  menuActiveIcon: string
  useIconType: number
  imgIcon?: string
  activeImgIcon?: string
}
const isNeedScroll = ref(false)

onMounted(() => {
  queryMenu()
})

const signInStatus = computed(() => Number(authStore.globalConfig?.signInStatus) === 1)

function toggleLogin() {
  if (isLogin.value)
    authStore.logOut()

  else
    authStore.setLoginDialog(true)
}

function checkMode() {
  const mode = darkMode.value ? 'light' : 'dark'
  appStore.setTheme(mode)
}

function setting() {
  if (!isLogin.value)
    authStore.setLoginDialog(true)

  else
    show.value = true
}
const { isMobile } = useBasicLayout()

const activeRoutePath = computed(() => {
  return route.path
})

function toPath(name: string) {
  router.push({ name })
}

const mobileSafeArea = computed(() => {
  if (isMobile.value) {
    return {
      paddingBottom: 'env(safe-area-inset-bottom)',
    }
  }
  return {}
})

const getMobileLayoutClass = computed(() => {
  if (isMobile.value)
    return ['flex-rol', 'w-full', 'border-0']
  return ['flex-col', 'w-sider']
})

const getIconMobileLayoutClass = computed(() => {
  if (isMobile.value)
    return ['flex', 'flex-rol', 'items-center', 'pt-0', 'w-full']
  return ['flex', 'flex-col', 'pt-1', 'items-center']
})

const iframeSrc = computed(() => useGlobalStore.iframeUrl)

function handleClickMenu(menu: MenuItem) {
  const { menuPath, isJump, menuIframeUrl, isNeedAuth } = menu
  if (isNeedAuth && !isLogin.value) {
    message.warning('请先登录后访问！')
    authStore.setLoginDialog(true)
    return
  }
  useGlobalStore.updateIframeUrl('')
  if (menuPath) {
    return router.push({ path: menuPath })
  }
  else {
    if (isJump) {
      window.open(menuIframeUrl)
    }
    else {
      useGlobalStore.updateIframeUrl(menuIframeUrl)
      router.push({ path: '/extend' })
    }
  }
}

function handleSignIn() {
  if (!isLogin.value) {
    authStore.setLoginDialog(true)
    return
  }
  useGlobalStore.updateSignInDialog(true)
}

function calcExceededTotalWidth() {
  if (!track.value)
    return
  const { clientHeight = 0, scrollHeight = 0 } = track.value
  isNeedScroll.value = scrollHeight > clientHeight
}

function isActive(item: MenuItem) {
  const { menuIframeUrl, menuPath } = item
  if (menuIframeUrl)
    return menuIframeUrl === iframeSrc.value

  if (menuPath)
    return menuPath === activeRoutePath.value
}

function getIcon(item: MenuItem) {
  const active = isActive(item)
  if (active)
    return item?.menuActiveIcon || item?.menuIcon

  else
    return item?.menuIcon || item?.menuActiveIcon
}

// 判断是中文还是英文
function decideChinaOrEng(text: string){
  const chineseRegex = /[\u4e00-\u9fa5]/;
  const englishRegex = /^[A-Za-z\s]+$/;
  if(chineseRegex.test(text)){
    return true
  }else if(englishRegex.test(text)){
    return false
  }
}

watch(
  isMobile,
  (val) => {
    appStore.setSiderCollapsed(val)
    appStore.setSiderDrawCollapsed(val)
  },
  {
    immediate: true,
    flush: 'post',
  },
)
</script>

<template>
  <!-- <div class="flex min-w-[81px] ml-[15px] shadow box-border rounded-md bg-white  dark:bg-[#1f1f1f ] " :class="getMobileLayoutClass" :style="mobileSafeArea"> -->
    <div class="flex min-w-[94px] box-border bg-white dark:bg-[#131314] border-r dark:border-r-[#343434]" :class="getMobileLayoutClass" :style="mobileSafeArea">
		<!-- <div class="px-2 w-full ele-drag items-center">
			<Logo  />
		</div> -->
    <!-- <macTablebar v-if="env === 'electron'" /> -->
    <main ref="track" class="flex-1 flex-grow-1 mb-5 overflow-auto" :class="[getIconMobileLayoutClass]">
      <NScrollbar :size="1">
        <div class="flex h-full  flex-col items-center space-y-4 ">
          <div v-for="item in list" :key="item.menuName" class="flex justify-center flex-col items-center py-[7px] px-[5px]" :class="[isMobile ? 'mt-0' : 'mt-3', isActive(item) ? 'rounded-md ' : '']" @click="handleClickMenu(item)">
            <NTooltip trigger="hover" placement="right">
            <template #trigger>
              <div v-if="item.useIconType === 1" class="h-12 w-12 border  cursor-pointer bg-white dark:bg-[#131314] dark:border-transparent rounded-lg duration-300 flex justify-center items-center" :class="[isActive(item) ? 'btns' : 'border-transparent',appStore.theme === 'dark' ? 'dark-mode' : 'light-mode']">
                <SvgIcon :icon="getIcon(item)" class="text-[24px]  transition-all" :class="[isActive(item) ? ' text-[#0057ff] dark:text-[#fff] ' : 'dark:text-[#9e9d9d]']" />
              </div>
              <div v-if="item.useIconType === 2">
                <img :src=" isActive(item) ? item.activeImgIcon : item.imgIcon" class="w-[45px] h-[45px] cursor-pointer  object-contain" :alt="item.name">
              </div>
          </template>
          <span >{{ item.menuTipText }}</span>
          </NTooltip>
            <span :style="[{fontFamily : decideChinaOrEng(item.menuTipText) == true ? '' : 'Arial'},{fontWeight: decideChinaOrEng(item.menuTipText) == true ? 'bold' : ''  } ]" class="text-[14px] mt-1 margin-auto  whitespace-nowrap overflow-hidden cursor-pointer font-bold"  :class="[isActive(item) ? 'text-[#0057ff] dark:text-[#d5d4d4]' : 'text-[#000] dark:text-[#d5d4d4]']">{{ item.menuTipText }}</span>
          </div>
        </div>
      </NScrollbar>
    </main>

    <!-- <HoverButton tooltip="全局设置" :placement="isMobile ? 'bottom' : 'right'" :class="isMobile ? 'pb-0' : 'pb-1' " @click="setting">
      <NIcon size="20" color="#555">
        <SvgIcon class="text-2xl" icon="fluent:dark-theme-24-regular" />
      </NIcon>
    </HoverButton> -->
    <!-- <HoverButton v-if="isLogin" tooltip="个人中心" :placement="isMobile ? 'bottom' : 'right'" :class="isMobile ? 'pb-0' : 'pb-8' " @click="toPath('UserCenter')"> -->
    <!-- <SvgIcon icon="icon-park-twotone:personal-collection" /> -->
    <!-- h-[140px] -->
    <div v-if="false" class="flex flex-col justify-between items-center">
      <NTooltip v-if="!isMobile && signInStatus" trigger="hover" placement="right">
        <template #trigger>
          <SvgIcon class="text-xl cursor-pointer mb-5" icon="mynaui:gift" @click="handleSignIn" />
        </template>
        签到奖励
      </NTooltip>

      <NTooltip v-if="!isMobile" trigger="hover" placement="right">
        <template #trigger>
          <SvgIcon class="text-xl cursor-pointer mb-5" :icon=" darkMode ? 'line-md:sunny-outline-to-moon-transition' : 'line-md:moon-to-sunny-outline-transition' " @click="checkMode" />
        </template>
        主题切换
      </NTooltip>

      <NTooltip v-if="isLogin" trigger="hover" placement="right">
        <template #trigger>
          <NAvatar :size="42" :src="avatar" round bordered :fallback-src="defaultAvatar" class="cursor-pointer" @click="toPath('UserCenter')" />
        </template>
        个人中心
      </NTooltip>

      <HoverButton v-if="!isLogin" tooltip="登录账户" :placement="isMobile ? 'bottom' : 'right'" :class="isMobile ? 'mb-0' : 'mb-5' " @click="toggleLogin">
        <NIcon size="20" color="#555">
          <component :is="logInIcon" />
        </NIcon>
      </HoverButton>
    </div>
  </div>
  <Setting v-if="show" v-model:visible="show" />
</template>

<style lang="less">
.btns{
  // background-color: #19191b !important;
  box-shadow: 0 4px 10px 0 rgba(0, 3, 15, 0.1);
}

.dark-mode.btns{
  background-color: #0057ff !important;
}



.sidebar{
	overflow: hidden;
	width: calc(100% - 5px);
}
.sidebar:hover{
	width: 100%;
	overflow: overlay;
}

.overlay{
	overflow: hidden;
}
.overlay:hover{
	width: 100%;
	overflow: overlay;
}

.active_bar{
	border-width: 1.5px;
}
</style>
