

<script lang='ts' setup>
import { useAuthStore , useAppStore} from '@/store'
import { computed } from 'vue'
import { NAlert} from 'naive-ui'
const authStore = useAuthStore()
const appStore = useAppStore()

const registerSendStatus = computed(() => Number(authStore.globalConfig.registerSendStatus))
const registerSendModel3Count = computed(() => Number(authStore.globalConfig.registerSendModel3Count))
const registerSendModel4Count = computed(() => Number(authStore.globalConfig.registerSendModel4Count))
const registerSendDrawMjCount = computed(() => Number(authStore.globalConfig.registerSendDrawMjCount))
const registerSendIndate = computed(() => Number(authStore.globalConfig.registerSendIndate))

// const registerTips = computed(() => (`首次注册：赠送${registerSendModel3Count.value}积分基础模型余额 | ${registerSendModel4Count.value}积分高级模型余额 | ${registerSendDrawMjCount.value}积分绘画余额`))
const registerTips = computed(() => (`首次注册：新用户注册免费试用${registerSendIndate.value}天`))
</script>

<template>
	<div v-if="registerSendStatus">
		<NAlert  :show-icon="false" :class="appStore.theme === 'dark' ? 'dark-mode' : 'light-mode'" class="mt-5  !text-center !w-full !bg-[#] !border-transparent !font-semibold">
			{{ registerTips }}
		</NAlert>
	</div>
</template>

<style lang="less" scoped >
::v-deep.n-alert{
	--n-border:1px solid transparent !important;
}

::v-deep.n-alert.light-mode .n-alert-body .n-alert-body__content{
	color: #0057ff !important;
	background-color: #f5f8ff !important;
}

// ::v-deep.n-alert.light-mode .n-alert-body .n-alert-body__content{
// 	color: #0057ff !important;
// }

</style>
