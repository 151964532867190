import { get, post } from '@/utils/request'

/* 查询app分组 */
export function fetchQueryAppCatsAPI<T>() {
  return get<T>({ url: '/app/queryCats' })
}

/*  查询全量app列表 */
export function fetchQueryAppsAPI<T>() {
  return get<T>({
    url: '/app/list',
  })
}

/*  查询个人app列表 */
export function fetchQueryMineAppsAPI<T>() {
  return get<T>({
    url: '/app/mineApps',
  })
}

/* 收藏app */
export function fetchCollectAppAPI<T>(data: { appId: number }) {
  return post<T>({ url: '/app/collect', data })
}

/* 收藏app */
export function fetchCustomAppAPI<T>(data) {
  return post<T>({ url: '/app/customApp', data })
}

/* 删除app */
export function fetchDelMineAppAPI<T>(data) {
  return post<T>({ url: '/app/delMineApp', data })
}

/*  查询全量app列表 */
export function fetchQueryOneCatAPI<T>(data) {
  return get<T>({
    url: '/app/queryOneCat',
    data,
  })
}

/*  获取随机app */
export function fetchQueryRandomAppAPI<T>() {
  return get<T>({
    url: '/app/random',
  })
}

/*  搜索gpts */
export function fetchQuersearchGptsAPI<T>(data: any) {
  return get<T>({
    url: '/models/searchGpts',
    data,
  })
}

/* 添加app到系统 */
export function fetchAddGptsFromUserAPI<T>(data: any) {
  return post<T>({ url: '/app/createGptsFromUser', data })
}

/* 管理员添加app到系统 */
export function fetchAddGptsFromSuperAPI<T>(data: any) {
  return post<T>({ url: '/app/createGptsFromSuper', data })
}
