import { defineStore } from 'pinia'
import type { PaintingState } from './helper'
import { getLocalSetting, setLocalSetting } from './helper'
import { store } from '@/store'

export const usePaintingStore = defineStore('painting', {
  state: (): PaintingState => getLocalSetting(),
  actions: {
    setAutoTranslate(translate: boolean) {
      this.autoTranslate = translate
      this.recordState()
    },

    setAutoOptimize(optimize: boolean) {
      this.autoOptimize = optimize
      this.recordState()
    },

    setSpeed(sp: string) {
      this.speed = sp
      this.recordState()
    },

    recordState() {
      setLocalSetting(this.$state)
    },

  },
})

export function usePaintingStoreWithOut() {
  return usePaintingStore(store)
}
