<script setup lang="ts">
import type { FormInst, FormItemInst, FormItemRule, FormRules } from 'naive-ui'
import { NButton, NCard, NForm, NFormItem, NGrid, NGridItem, NInput, useMessage } from 'naive-ui'
import { ref ,computed } from 'vue'
import { useBasicLayout } from '@/hooks/useBasicLayout'
import { useAuthStore, useGlobalStore} from '@/store'
import { fetchResetPsdAsEmail,fetchCodeAsEmail } from '@/api/user'
import type { ResData } from '@/api/types'
import sendCodeBtn from './sendCodeBtn.vue'

const globalStore = useGlobalStore()
const resetState = computed(() => globalStore.changeResetState )

interface ModelType {
  SmsCode: string
  password: string
  reenteredPassword: string,
  email: string
}

const modelRef = ref<ModelType>({
  SmsCode: '',
  password: '',
  reenteredPassword: '',
  email: ''
})
const model = modelRef

const formRef = ref<FormInst | null>(null)

const rPasswordFormItemRef = ref<FormItemInst | null>(null)

const rules: FormRules = {
  email: [
    {
      required: true,
      min: 6,
      message: '请输入正确的的账号',
      trigger: ['blur'],
    },
  ],
  SmsCode: [
    {
      required: true,
      min: 6,
      message: '请输入正确的6位数的验证码',
      trigger: ['blur'],
    },
  ],
  password: [
    {
      required: true,
      message: '请输入密码',
    },
  ],
  reenteredPassword: [
    {
      required: true,
      message: '请再次输入密码',
      trigger: ['input', 'blur'],
    },
    {
      validator: validatePasswordStartWith,
      message: '两次密码输入不一致',
      trigger: 'input',
    },
    {
      validator: validatePasswordSame,
      message: '两次密码输入不一致',
      trigger: ['blur', 'password-input'],
    },
  ],
}
function validatePasswordStartWith(
  rule: FormItemRule,
  value: string,
): boolean {
  return (
    !!modelRef.value.password
        && modelRef.value.password.startsWith(value)
        && modelRef.value.password.length >= value.length
  )
}
function validatePasswordSame(rule: FormItemRule, value: string): boolean {
  return value === modelRef.value.password
}
function handlePasswordInput() {
  if (modelRef.value.reenteredPassword)
    rPasswordFormItemRef.value?.validate({ trigger: 'password-input' })
}

const { isSmallXl } = useBasicLayout()
const authStore = useAuthStore()
const ms = useMessage()


async function updateEmailEmit(){
  const email = modelRef.value.email;
  let results = await fetchCodeAsEmail({ email })
  if(results) ms.success('验证码发送成功，请注意查收')
}


// 重置密码
async function updatePasswordAsEmail(options: { SmsCode: string; password: string; email: string }) {
  const res: ResData = await fetchResetPsdAsEmail(options)
  if (res.success)
    ms.success('密码更新成功、请重新登录系统！')
    resetForm()
    authStore.updatePasswordSuccess()
}



function resetForm() {
  modelRef.value = {
    email: '',
    SmsCode: '',
    password: '',
    reenteredPassword: '',
  }
}

function accountLogin(){
  globalStore.updateForgetShow(false)
  globalStore.updateState("")
}


function handleValidate(e: MouseEvent) {
  e.preventDefault()
  formRef.value?.validate(async (errors) => {
    if (!errors) {
      const { SmsCode, password, email } = modelRef.value
        updatePasswordAsEmail({ SmsCode, password, email})
    }
  })
}
</script>

<template>
  <NCard>
    <NGrid :x-gap="24" :y-gap="24" :cols=" isSmallXl ? 1 : 3" class="mt-3">
      <NGridItem class="border rounded-sm p-3  dark:border-[#ffffff17]" span="4">
        <NForm ref="formRef" :model="model" :rules="rules">
          <NFormItem path="email" label="邮箱">
            <NInput v-model:value="model.email" @keydown.enter.prevent />
            <sendCodeBtn :sendPhone="modelRef.email" @update-phone="updateEmailEmit"></sendCodeBtn>
          </NFormItem>
          <NFormItem path="SmsCode" label="验证码">
            <NInput v-model:value="model.SmsCode" @keydown.enter.prevent />
          </NFormItem>
          <NFormItem path="password" label="新密码">
            <NInput
              v-model:value="model.password"
              type="password"
              @input="handlePasswordInput"
              @keydown.enter.prevent
              password-visible-icon
            />
          </NFormItem>
          <NFormItem
            ref="rPasswordFormItemRef"
            first
            path="reenteredPassword"
            label="确认密码"
          >
            <NInput
              v-model:value="model.reenteredPassword"
              :disabled="!model.password"
              type="password"
              tabindex="0"
              @keyup.enter="handleValidate"
              password-invisible-icon
            />
          </NFormItem>

          <div class="flex justify-between">
            <!-- <span class="text-[#95AAC9]">更新密码完成后将重新登录！</span> -->
            <NButton
              :disabled="model.SmsCode === null"
              type="primary"
              @click="handleValidate"
            >
              更新您的密码
            </NButton>
            <NButton
              type="primary"
              @click="accountLogin"
            >
              账号登录
            </NButton>

          </div>
        </NForm>
      </NGridItem>
    </NGrid>
  </NCard>
</template>
