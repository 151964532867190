import { defineStore } from 'pinia'
import type { GlobalState, OrderInfo } from './helper'
import { store } from '@/store'
import { ss } from '@/utils/storage'

export const useGlobalStore = defineStore('global-store', {
  state: (): GlobalState => ({
    payDialog: false,
    goodsDialog: false,
    noticeDialog: false,
    bindWxDialog: false,
    signInDialog: false,
    modelDialog: false,
    isChatIn: false,
    fingerprint: 0,
    model: ss.get('model') || 3,
    orderInfo: {} as OrderInfo,
    iframeUrl: '',
    clipboardText: '',
    forgetShow: false,
    changeResetState: '',
    currentGptIcon:"",
    balanceNotDialog:false,
    paySuccesslog:false,
    warnNotDialog:false
  }),

  actions: {
    updateClipboardText(text: string) {
      this.clipboardText = text
    },

    updateFingerprint(str: number) {
      let id = str
      /* 超过mysql最大值进行截取 */
      if (id > 2147483647) {
        id = Number(id.toString().slice(-9))
        id = Number(String(Number(id)))
      }
      ss.set('fingerprint', id)
      this.fingerprint = id
    },

    updateIframeUrl(iframeUrl: string) {
      this.iframeUrl = iframeUrl
    },

    updatePayDialog(payDialog: boolean) {
      this.payDialog = payDialog
    },

    //
    updateModelDialog(modelDialog: boolean) {
      this.modelDialog = modelDialog
    },
    updateBalanceNotDialog(modelDialog: boolean) {
      this.balanceNotDialog = modelDialog
    },

    // 打开对话组配置
    openChatGroupModelDialog() {
      if (this.isChatIn)
        return window.$message.warning('请等待聊天结束后修改模型信息！')

      this.updateModelDialog(true)
    },

    updateIsChatIn(isChatIn: boolean) {
      this.isChatIn = isChatIn
    },

    updateGoodsDialog(goodsDialog: boolean) {
      this.goodsDialog = goodsDialog
    },

    updateBindwxDialog(bindWxDialog: boolean) {
      this.bindWxDialog = bindWxDialog
    },

    updateSignInDialog(signInDialog: boolean) {
      this.signInDialog = signInDialog
    },

    updateNoticeDialog(noticeDialog: boolean) {
      this.noticeDialog = noticeDialog
    },

    updateOrderInfo(orderInfo: OrderInfo | {}) {
      this.orderInfo = orderInfo
    },

    updateModel(model: number) {
      ss.set('model', model)
      this.model = model
    },
    updateForgetShow(isShow: boolean) {
      this.forgetShow = isShow
    },
    updateState(state: string) {
      this.changeResetState = state
    },
    updateModelIcon(state: string){
      this.currentGptIcon = state 
    },
    updatePaySuccesslog(state: boolean){
      this.paySuccesslog = state 
    },
    updateWarnNotDialog(state: boolean){
      this.warnNotDialog = state 
    }
  },
})

export function useGlobalStoreWithOut() {
  return useGlobalStore(store)
}
