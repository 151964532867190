<script setup lang="ts">
import { NModal } from 'naive-ui'
import { useGlobalStore } from '@/store'
import { useRouter } from 'vue-router'
import { useBasicLayout } from '@/hooks/useBasicLayout'
const useGlobal = useGlobalStore()
const router = useRouter()
const { isMobile } = useBasicLayout()

defineProps<Props>()

interface Props {
  visible: boolean
}

function openVIP(){
  useGlobal.updateBalanceNotDialog(false)
  if(isMobile.value){
    useGlobal.updateGoodsDialog(true)
    return
  }
  router.push({ name: 'Pay' })
}

</script>

<template>
  <NModal :show="visible">
    <div class="balanceBox" >
      <div class="main rounded-2xl" :style="{ width: isMobile ? '80%' : '35%' , height: isMobile ? '30%' : '30%'}">
        <div class="main-header text-center">
          <span class="font-semibold"></span>
          <span class="close" @click="useGlobal.updateBalanceNotDialog(false)">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512"><path d="M289.94 256l95-95A24 24 0 0 0 351 127l-95 95l-95-95a24 24 0 0 0-34 34l95 95l-95 95a24 24 0 1 0 34 34l95-95l95 95a24 24 0 0 0 34-34z" fill="currentColor"></path></svg>
          </span>
        </div>
        <div class="content" :class="[ isMobile ? 'mt-2' : 'mt-10' ]">
          <div class="tips font-semibold text-center" :style="{ fontSize: isMobile ? '14px' : '16px'}">当前对话余额不足</div>
          <div class="font-semibold text-center mt-6 text-lg" :class="[ isMobile ? 'text-sm' : 'text-base' ]" >您可以开通会员获得更多套餐权益</div>
          <div class="text-center" :class="[ isMobile ? 'mt-10' : 'mt-10' ]" >
            <span class="openVips font-semibold" :style="{ fontSize: isMobile ? '13px' : '16px'}" @click="openVIP">开通会员</span>
          </div>
        </div>
      </div>
  </div>
  </NModal>
</template>

<style scoped lang="less">
.balanceBox{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items:center;
  z-index: 99;
  background-color: rgba(127, 127, 127, .4);
  .main{
    // width: 40%;
    // height: 40%;
    background-color: #fff;
    opacity: 1;
    padding: 20px;
    .main-header{
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      .close{
        width: 20px;
        cursor: pointer;
        svg{
          color: #909399;
        }
      }
    }
    .content{
      // margin-top: 20px;
      .recharge{
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        
      }
      .openVips{
        width: 90px;
        background-color: #0057ff;
        padding: 8px 18px;
        border-radius: 20px;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}
</style>